import validate from "/builds/stavax/stavax-frontend/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+_babc87cbe0d4ace1763ea23d87791bc0/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45mpc_45global from "/builds/stavax/stavax-frontend/middleware/00.mpc.global.ts";
import _01_45wallet_45session_45global from "/builds/stavax/stavax-frontend/middleware/01.walletSession.global.ts";
import _02_45auth_45global from "/builds/stavax/stavax-frontend/middleware/02.auth.global.ts";
import manifest_45route_45rule from "/builds/stavax/stavax-frontend/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+_babc87cbe0d4ace1763ea23d87791bc0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _00_45mpc_45global,
  _01_45wallet_45session_45global,
  _02_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}