import { useWebAppCloudStorage as useTgCloud, useWebApp } from "vue-tg";

export function useWebAppCloudStorage() {
  try {
    if (!useWebApp().isReady.value) throw new Error("");
    const tgCloud = useTgCloud();
    return tgCloud;
  } catch (error) {
    return {
      setStorageItem: () => {},
      getStorageItem: () => {},
      getStorageItems: () => {},
      removeStorageItem: () => {},
      removeStorageItems: () => {},
      getStorageKeys: () => {},
    };
  }
}
