import { useMPCKeyShare } from "#staxmpc/composables/mpcKeyshare";
import { SignAlgo } from "#staxmpc/lib/dkls";

import { encodeHex } from "#staxmpc/lib/encode";
import { publicKeyConvert } from "secp256k1";
import { hashMessage, hashTypedData, type Hex } from "viem";
import { publicKeyToAddress } from "viem/accounts";
import { WALLET_TYPE } from "./type";
import { BLOCKCHAIN_TYPE } from "../api/auth/type";
import Crypto from "../encrypt/EncryptionService";
import { createSmartWallet, getUserInfo } from "../api/auth/api";
import { getTgCloudData, updateTgCloudData } from "../api/drive/mpc_api";

const CLOUD_STORE_KEY = "EVM_MPC_SHARE1";
const MPC_CLOUD_META_KEY = "EVM_MPC_WALLET_META";
const MPC_LOCAL_STORE_KEY = "MPC_LOCAL_STORE_KEY";

export function useMPCWallet(storePwd: string) {
  const { getUser, setUser } = useAuthStore();

  const smart_data = computed(() => getUser().smart_wallets.find((w) => w.blockchain === "evm"));
  const wallet = useMPCKeyShare(storePwd, SignAlgo.ECDSA);
  const mpcStore = useMPCStore(storePwd);
  const tgCloud = useWebAppCloudStorage();
  const tgCloudBackuped = ref(false);

  watch(
    () => mpcStore.isReady,
    () => console.log("mpcStore.isReady", mpcStore.isReady)
  );
  watch(
    () => smart_data,
    () => console.log("mpc smart_data", smart_data)
  );

  async function signMessage(message: string, option?: { hash?: boolean }) {
    console.log("signMessage", wallet.keyshare.value);
    if (!wallet || !wallet.keyshare.value) {
      throw new Error("MPC Wallet not found");
    }
    const hash = option?.hash ? (message as Hex) : hashMessage(message);

    const signature = await wallet.signMessage(hash);

    return signature as `0x${string}`;
  }
  async function signTypedData(payload: any, provider = "EVM") {
    console.log("signTypedData", wallet.keyshare.value);

    if (!wallet || !wallet.keyshare.value) {
      throw new Error("MPC Wallet not found");
    }
    const signature = await wallet.signMessage(hashTypedData(payload as any));

    return signature as `0x${string}`;
  }

  async function clearCloudStore() {
    console.log("start check clear");
    const metaSaved = (await tgCloud.getStorageItem(MPC_CLOUD_META_KEY)) || "";
    if (metaSaved) {
      console.log(" clear");

      // const meta = JSON.parse(metaSaved);
      // const arrSaved: string[] = [];

      // for (let i = 0; i < meta.num_of_part; i++) {
      //   arrSaved.push(`${CLOUD_STORE_KEY}_${meta.version}_${i}`);
      // }
      // await tgCloud.removeStorageItems(arrSaved);
      await tgCloud.removeStorageItem(MPC_CLOUD_META_KEY);
    }
  }

  async function getSavedWallet() {
    let currentShare1 = "";
    try {
      const metaSaved = (await tgCloud.getStorageItem(MPC_CLOUD_META_KEY)) || "";
      const serverMeta = metaSaved ? JSON.parse(metaSaved) : null;
      const localMeta = JSON.parse(localStorage.getItem(MPC_LOCAL_STORE_KEY) || "{}");

      const needUpdate = serverMeta ? (serverMeta?.seqno || 1) > (localMeta?.seqno || 0) : false;
      if (needUpdate) {
        console.log("start backup from tele");

        localStorage.setItem(MPC_LOCAL_STORE_KEY, JSON.stringify({ ...serverMeta, seqno: serverMeta.seqno || 1 }));
        const arrSaved = [];
        for (let i = 0; i < 9; i++) {
          arrSaved.push(tgCloud.getStorageItem(`${CLOUD_STORE_KEY}_${serverMeta.version}_${i}`));
        }
        const arrSaveFromServer = getTgCloudData();
        const arrPartShare1 = (await Promise.all([...arrSaved, arrSaveFromServer])).filter((item) => !!item);
        if (arrPartShare1.length !== 10) {
          clearCloudStore();
          throw new Error("Part of share1 exist null");
        }

        const share1 = arrPartShare1.join("");
        await mpcStore.unlock(storePwd);
        currentShare1 = mpcStore.isEmpty ? "" : await mpcStore.exportShare1(storePwd);

        !!currentShare1 && (await mpcStore.clear(storePwd));
        mpcStore.lock();

        await mpcStore.importShare1(share1, storePwd);
        tgCloudBackuped.value = true;
        mpcStore.unlock(storePwd);
        console.log("store done");
      }
    } catch (error) {
      console.log("getSavedWallet error", error);
      mpcStore.lock();
      currentShare1 && (await mpcStore.importShare1(currentShare1, storePwd));
    }
  }

  async function checkSavedToTgCloud() {
    try {
      if (tgCloudBackuped.value) return true;
      const metaSaved = (await tgCloud.getStorageItem(MPC_CLOUD_META_KEY)) || "";

      if (metaSaved) {
        const localMeta = JSON.parse(localStorage.getItem(MPC_LOCAL_STORE_KEY) || "{}");
        const meta = JSON.parse(metaSaved);
        let backuped = meta ? !localMeta : false;
        if (!backuped) {
          if (localMeta) {
            backuped = (meta.seqno || 0) > (localMeta.seqno || 0);
          }
        }
        tgCloudBackuped.value = backuped;
      } else tgCloudBackuped.value = false;
      return tgCloudBackuped.value;
    } catch (error) {
      console.log("checkSavedToTgCloud", error);
      return false;
    }
  }
  async function saveWalletToCloud(callbackProgress?: (progress: number) => void) {
    const share1 = await mpcStore.exportShare1(storePwd);
    if (!share1) return false;
    let count = 0;
    let version = "v1";
    let seqno = 1;
    const JUMP = 4000;
    const totalPart = Math.ceil(share1.length / JUMP);
    console.log("saveWalletToCloud", share1.length, totalPart);
    const currentMeta = await tgCloud.getStorageItem(MPC_CLOUD_META_KEY);
    if (currentMeta) {
      const meta = JSON.parse(currentMeta);
      version = meta.version;
      seqno = (meta.seqno || 1) + 1;
    }

    while (count < 9) {
      const res = await tgCloud.setStorageItem(`${CLOUD_STORE_KEY}_${version}_${count}`, share1.slice(JUMP * count, JUMP * (count + 1)));

      if (!res) throw new Error("Failed to save share1 to telegram cloud");
      const progress = ((count + 1) / (totalPart + 1)) * 100;
      callbackProgress?.(progress);
      console.log("uploadCloudProgress", progress);
      ++count;
    }

    const res = await updateTgCloudData(share1.slice(JUMP * 9, share1.length));
    if (!res) throw new Error("Failed to update cloud data");
    const itemMeta = {
      version,
      seqno,
      num_of_part: 9,
      share_length: share1.length,
      cloud_key: CLOUD_STORE_KEY,
      key_id: wallet.keyshare.value?.keyId || "",
    };
    localStorage.setItem(MPC_LOCAL_STORE_KEY, JSON.stringify(itemMeta));
    tgCloud.setStorageItem(MPC_CLOUD_META_KEY, JSON.stringify(itemMeta));
    tgCloudBackuped.value = true;
    callbackProgress?.(100);
    console.log("save mpc to tg cloud done");
    return true;
  }

  async function generateWallet(pwd: string, hint: string) {
    if (!mpcStore.isUnlocked) {
      console.log("getUser()", getUser().id);
      await mpcStore.unlock(storePwd);
    }

    if (smart_data.value?.smart_address) return wallet;

    const [share1, share2, _] = await wallet.generateWallet();

    await mpcStore.setSharesByAlgo(SignAlgo.ECDSA, share1, share2);

    const eoa_address = publicKeyToAddress(("0x" + encodeHex(publicKeyConvert(wallet.keyshare.value!.publicKey, false))) as Hex);
    const enc = Crypto.encrypt(eoa_address, pwd);
    const key_id = wallet.keyshare.value?.keyId || "";
    const firstWallet = getUser().smart_wallets[0];
    const _hint = hint || firstWallet?.data?.password_hint || "";
    await createSmartWallet(enc, eoa_address, _hint, "evm", key_id);

    const newUser = await getUserInfo();
    setUser(newUser);
    return wallet;
  }

  async function clear(id: string) {
    if (mpcStore.isLocked) {
      await mpcStore.unlock(id);
    }
    await mpcStore.clear(id);
  }

  return {
    address: computed(() => {
      if (!wallet.keyshare.value) {
        return "";
      }

      return publicKeyToAddress(("0x" + encodeHex(publicKeyConvert(wallet.keyshare.value!.publicKey, false))) as Hex);
    }),
    get smart_data() {
      return smart_data.value;
    },
    get smart_address() {
      return smart_data.value?.smart_address;
    },
    algo: computed(() => wallet.keyshare.value?.algo),
    wallet_type: "evm_mpc" as WALLET_TYPE,
    blockchain: "evm" as BLOCKCHAIN_TYPE,
    get name() {
      return getUser().user_wallets.find((w) => w.address === smart_data.value?.smart_address)?.name || "";
    },
    smart_name: "EVM Smart Wallet",
    is_smart: true,
    getSavedWallet,
    signMessage,
    generateWallet,
    signTypedData,
    clear,
    saveWalletToCloud,
    checkSavedToTgCloud,
    get tgCloudBackuped() {
      return tgCloudBackuped.value;
    },
  };
}
