import revive_payload_client_HZ3Iqh3oeM from "/builds/stavax/stavax-frontend/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+_babc87cbe0d4ace1763ea23d87791bc0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_AicaYL02As from "/builds/stavax/stavax-frontend/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+_babc87cbe0d4ace1763ea23d87791bc0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_x5roSHBBO4 from "/builds/stavax/stavax-frontend/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+_babc87cbe0d4ace1763ea23d87791bc0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_V117HLQa9H from "/builds/stavax/stavax-frontend/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+_babc87cbe0d4ace1763ea23d87791bc0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_3zuM2wj4fZ from "/builds/stavax/stavax-frontend/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+_babc87cbe0d4ace1763ea23d87791bc0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_aOvEX0BhLy from "/builds/stavax/stavax-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_typescript@5.4.5_vue@3.4.27_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/stavax/stavax-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_pMyVEqaRJ4 from "/builds/stavax/stavax-frontend/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+_babc87cbe0d4ace1763ea23d87791bc0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_txQ9KBRhSo from "/builds/stavax/stavax-frontend/node_modules/.pnpm/@sentry+nuxt@8.30.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+_2f791b735a472b2b892347b2a09bde89/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/builds/stavax/stavax-frontend/.nuxt/sentry-client-config.mjs";
import plugin_Zuy78wYxhc from "/builds/stavax/stavax-frontend/node_modules/.pnpm/nuxt-vue3-google-signin@0.0.11_rollup@4.18.0_vue@3.4.27_typescript@5.4.5_/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import chunk_reload_client_fEWyAmUIAw from "/builds/stavax/stavax-frontend/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+_babc87cbe0d4ace1763ea23d87791bc0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import w3modal_plugin_RHCGNVvbOh from "/builds/stavax/stavax-frontend/plugins/w3modal-plugin.ts";
import wc_f8iccmtoWt from "/builds/stavax/stavax-frontend/plugins/wc.ts";
export default [
  revive_payload_client_HZ3Iqh3oeM,
  unhead_AicaYL02As,
  router_x5roSHBBO4,
  payload_client_V117HLQa9H,
  check_outdated_build_client_3zuM2wj4fZ,
  plugin_vue3_aOvEX0BhLy,
  components_plugin_KR1HBZs4kY,
  prefetch_client_pMyVEqaRJ4,
  sentry_client_txQ9KBRhSo,
  sentry_client_config_o34nk2sJbg,
  plugin_Zuy78wYxhc,
  chunk_reload_client_fEWyAmUIAw,
  w3modal_plugin_RHCGNVvbOh,
  wc_f8iccmtoWt
]