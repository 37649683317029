import { defineNuxtRouteMiddleware } from "#imports";
import { useWebApp } from "vue-tg";
import { authTelegram, getUserInfo, loginByProject, postUpdateAuthSecret } from "~/services/api/auth/api";
import { getRemoteConfig } from "~/services/api/session/api";
import Crypto from "~/services/encrypt/EncryptionService";
import PasswordService from "~/services/security/PasswordService";
import { useWalletStore } from "~/services/WalletManager/store";
// import { getDkgResult } from "~/services/SmartWallet";
import { useAuthStore } from "~/stores/auth";
import { useTokens } from "~/stores/tokens";

const requiredAuthLayouts = ["dashboard", "bottom-tab", "onboard"];

export default defineNuxtRouteMiddleware(async (to, from) => {
  const webApp = useWebApp();
  const route = useRoute();
  const { setAccessToken, getAccessToken, setUser, getUser } = useAuthStore();

  if (route.query.id && route.query.hash) {
    sessionStorage.setItem("init_data", new URLSearchParams(route.query as any).toString());
  }
  if (!to.meta.layout || to.meta.guest || !requiredAuthLayouts.includes(to.meta.layout)) return;

  const initData = webApp.initData || sessionStorage.getItem("init_data");
  const app = useAppSetting();

  if (initData && !getAccessToken()) {
    const res =
      webApp.platform === "stv_iframe"
        ? await loginByProject("telegram", {
            init_data: initData,
          })
        : await authTelegram(initData);

    if (!res) {
      return navigateTo("/onboard", {
        replace: true,
      });
    }

    if (res.status === 403 && webApp.platform === "stv_iframe") {
      return navigateTo("/onboard/allowance", {
        replace: true,
      });
    }

    if (res.access_token) {
      setAccessToken(res.access_token);
    }
  }

  let userInfo = getUser();
  if (!userInfo?.id && getAccessToken()) {
    userInfo = await getUserInfo();
    setUser(userInfo);
  }

  if (!userInfo.id) {
    localStorage.removeItem("access_token");
    const query = { provider: "telegram", token: initData };
    if (sessionStorage.getItem("init_data")) {
      return navigateTo("/onboard/password");
    }
    if (to.path.startsWith("/onboard")) return;
    return navigateTo({ query, path: "/onboard" }, { replace: true });
  }

  const teleUser = userInfo?.auth_methods?.find((m) => m.provider === "telegram");

  if (webApp?.initDataUnsafe?.user?.id && teleUser && teleUser.metadata.user.id !== webApp.initDataUnsafe.user?.id) {
    setAccessToken("");
    setUser(undefined);
    return navigateTo("/onboard");
  }

  const mpcStore = useMPCStore(userInfo.id);

  if (userInfo.id && !mpcStore.isUnlocked) {
    await mpcStore.unlock(userInfo.id);
  }

  const wallets = useWalletStore();

  await wallets.init();

  console.log("auth middleware", to.path);

  if ((userInfo?.smart_wallets?.length || 0) > 0) {
    let checkExpire = false;

    userInfo.smart_wallets?.forEach((w) => {
      if (!w?.blockchain) return;
      const wallet = wallets.findWalletWithAddress(w.smart_address);

      if (!wallet) {
        // khong co wallet o device nay
        checkExpire = true;
      } else if (wallet.address !== w.eoa_address) {
        //  co wallet nhung da khac server
        checkExpire = true;
        // wallet.clear(userInfo.id);
      }
    });
    if (checkExpire) {
      if (mpcStore.isLocked) {
        await mpcStore.unlock(userInfo.id);
      }
      await mpcStore.clear(userInfo.id);
      const mpcWallet = wallets.findWalletByType("evm");
      if (mpcWallet) {
        await mpcWallet.getSavedWallet();
        checkExpire = false;
        userInfo.smart_wallets?.forEach((w) => {
          if (!w?.blockchain) return;
          const wallet = wallets.findWalletWithAddress(w.smart_address);
          if (!wallet) {
            // khong co wallet o device nay
            checkExpire = true;
          } else if (wallet.address !== w.eoa_address) {
            //  co wallet nhung da khac server
            checkExpire = true;
            // wallet.clear(userInfo.id);
          }
        });
      }
    }
    console.log("check mpc", wallets.existMPCWallet, mpcStore.isEmpty, checkExpire);
    if (wallets.existMPCWallet && (mpcStore.isEmpty || checkExpire)) {
      if (to.path === "/onboard/restore") return;
      return navigateTo("/onboard/restore", {
        replace: true,
      });
    }
  } else if (!wallets.checkExistImportWallet()) {
    if (to.path.startsWith("/onboard") || to.path.startsWith("/account/upgrade-wallet")) return;

    return navigateTo("/onboard", {
      replace: true,
    });
  }

  const pwd = PasswordService.getPassword();
  if (!pwd) {
    if (to.path.startsWith("/onboard/sign-in")) return;
    app.changeNextHref(to.path);

    return navigateTo("/onboard/sign-in", {
      replace: true,
    });
  }
  if (!getUser().settings.encrypted_auth) {
    postUpdateAuthSecret(Crypto.encrypt(getUser().id, pwd));
  }
  await wallets.initImportWallet();
  const setting = useSettingStore();
  if (setting.getSetting().remoteConfig.supported_networks) {
    getRemoteConfig().then((res) => {
      setting.setSetting("remoteConfig", res);
    });
  } else {
    const config = await getRemoteConfig();

    setting.setSetting("remoteConfig", config);
  }
  await useTokens().init();

  // if (to.path.startsWith("/onboard")) return;
  //TODO: remove after update bot webapp url
  if (to.path.startsWith("/telegram")) {
    return navigateTo("/", {
      replace: true,
    });
  }
  return;
});
